<template>
  <div class="table">
    <el-table :data="tableData" v-on="events" style="width: 100%">
      <template v-for="item in cols">
        <el-table-column
          :type="item.type"
          :key="item.prop"
          :formatter="item.formatter"
          :width="item.width"
          align="center"
          :prop="item.prop"
          :label="item.label"
        >
        </el-table-column>
      </template>
      <el-table-column
        fixed="right"
        width="200"
        label="操作"
        align="center"
        v-if="operation"
      >
        <template slot-scope="scope">
          <el-button
            v-if="operation.details"
            size="mini"
            type="text"
            @click="handleEventButton('details', scope.row)"
            >查看</el-button
          >
          <el-button
            v-if="operation.look"
            size="mini"
            type="text"
            @click="handleEventButton('look', scope.row)"
            >查看人员</el-button
          >
          <el-button
            v-if="operation.task"
            size="mini"
            type="text"
            @click="handleEventButton('task', scope.row)"
            >任务查看</el-button
          >
          <el-button
            v-if="operation.inte"
            size="mini"
            type="text"
            @click="handleEventButton('inte', scope.row)"
            >修改积分</el-button
          >
          <el-button
            v-if="operation.invite"
            size="mini"
            type="text"
            @click="handleEventButton('invite', scope.row)"
            >我的邀请</el-button
          >
          <el-button
            v-if="operation.inteNum"
            size="mini"
            type="text"
            @click="handleEventButton('inteNum', scope.row)"
            >修改总积分</el-button
          >
          <el-button
            v-if="operation.edit"
            size="mini"
            type="text"
            @click="handleEventButton('edit', scope.row)"
            >编辑</el-button
          >
          <el-button
            v-if="operation.edit1"
            size="mini"
            type="text"
            :disabled="scope.row.checkStatus == 2 ? false : true"
            @click="handleEventButton('edit', scope.row)"
            >编辑</el-button
          >
          <el-button
            v-if="operation.pass"
            size="mini"
            :disabled="scope.row.clueStatus == 2 ? false : true"
            type="text"
            @click="handleEventButton('pass', scope.row)"
            >通过</el-button
          >
          <el-button
            v-if="operation.pass1"
            size="mini"
            :disabled="scope.row.checkStatus == 2 ? false : true"
            type="text"
            @click="handleEventButton('pass1', scope.row)"
            >通过</el-button
          >
          <el-button
            v-if="operation.reject"
            size="mini"
            :disabled="scope.row.clueStatus == 2 ? false : true"
            type="text"
            @click="handleEventButton('reject', scope.row)"
            >驳回</el-button
          >
          <el-button
            v-if="operation.reject1"
            size="mini"
            :disabled="scope.row.checkStatus == 2 ? false : true"
            type="text"
            @click="handleEventButton('reject1', scope.row)"
            >驳回</el-button
          >
          <el-button
            v-if="operation.del"
            size="mini"
            type="text"
            @click="handleEventButton('del', scope.row)"
            >删除</el-button
          >
        </template>
      </el-table-column>
    </el-table>
    <div class="pagination" v-if="page">
      <el-pagination
        @current-change="handleCurrentChange"
        :page-size="page.pageSize"
        layout="total, prev, pager, next"
        :total="page.total"
      >
      </el-pagination>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    // table 标题
    cols: {
      type: Array,
      default: () => [],
    },
    // table 数据
    tableData: {
      type: Array,
      default: () => [],
    },
    // table 事件
    events: {
      type: Object,
      default: () => {},
    },
    // 操作按钮
    operation: {
      type: Object,
      default: () => {},
    },
    // 分页
    page: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {};
  },
  methods: {
    handleEventButton(type, row) {
      this.$emit("handleEventButton", type, row);
    },
    handleCurrentChange(val) {
      // console.log(`当前页: ${val}`);
      this.$emit("handleEventButton", "current", val);
    },
  },
};
</script>

<style lang="less" scoped>
.table {
  border-bottom-right-radius: 16px;
  border-bottom-left-radius: 16px;
  background-color: #fff;
  overflow-y: auto;
  border-radius: 16px;

  .pagination {
    padding: 30px 0;
    display: flex;
    justify-content: flex-end;
    /deep/ .el-pagination {
      .el-pagination__sizes {
        margin-right: 100px;
      }
      .el-pagination__jump {
        margin-right: 100px;
      }
    }
  }
}
</style>